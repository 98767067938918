<template>
  <div class="alteration">
    <div class="head-title">
        <div class="line" @click="hotLine"><a-icon style="margin-right:8px" class="icon" type="phone"/>服务热线</div>
        <div><span>© 软控股份有限公司</span></div>
    </div>
    <div class="login_form_wp">
      <a-spin :spinning="confirmLoading">
        <div class="login-logo"></div>
        <div class="login-title">
            软控协同办公平台
        </div>
        <div v-if="!ifChange" class="user-layout-alteration" >
            <a-input size="large" v-model="username" placeholder="请输入用户名" >
              <a-icon slot="prefix" type="user" style="color:#BC0039" />
            </a-input>
            <a-row :gutter="4" style="marginTop:20px;">
              <a-col :span="16">
                  <a-input
                      v-model="yzm"
                      size="large"
                      type="text"
                      placeholder="请输入验证码"
                  >
                      <a-icon  slot="prefix" type="smile" style="color:#BC0039" />
                  </a-input>
              </a-col>
              <a-col :span="8" style="text-align: right; position: relative;" >
                  <button class="obtain" ref="eleCode" @click.stop="obtainYzm">  {{codeText}} </button>
              </a-col>
            </a-row>
            <div class="backLogin">
              <span @click="$router.push('/login')">返回登录</span>
            </div>
            <div class="contrast_btn_warp">
              <a-button class="contrast" @click="contrastOK">确定</a-button>
            </div>
            
        </div>
        <div v-else class="user-layout-alteration">
          <a-form :form="form">
            <a-form-item>
              <a-input
                  v-decorator="['username', {initialValue: username}, validatorRules.username,{ validator: handleUsernameOrEmail }]"
                  size="large"
                  type="text"
                  placeholder="请输入修改账号"
              >
                  <a-icon slot="prefix" type="user" style="color:#BC0039" />
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-input-password
                  v-decorator="['password',validatorRules.password]"
                  size="large"
                  autocomplete="false"
                  placeholder="请输入修改密码"
              >
                  <a-icon slot="prefix" type="lock" style="color:#BC0039" />
              </a-input-password>
            </a-form-item>

            <div class="backLogin">
              <span @click="$router.push('/login')">返回登录</span>
            </div>

            <div class="contrast_btn_warp">
                    <a-button
                        size="large"
                        type="primary"
                        html-type="submit"
                        class="contrast"
                        :loading="loginBtn"
                        :disabled="loginBtn"
                        @click.stop.prevent="handleSubmit"
                    >修改</a-button>
                </div>
          </a-form>
        </div>
      </a-spin>
      
    </div>
    <server-line ref="lineForm" />
  </div>
</template>
<script>
import {getAction} from '@/api/manage'
import serverLine from '@/views/serverLine'
export default {
  components: {serverLine},
  data() {
    return {
      username:'',
      yzm:'',
      loginBtn: false,
      ifChange: false,
      confirmLoading: false,
      codeText: "获取验证码",
      timer: 60, //倒计时时间
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      form: this.$form.createForm(this),
      validatorRules:{
        username: { rules: [{ required: true, message: '请输入用户名!' }, { validator: this.handleUsernameOrEmail }] },
        password: { rules: [{ required: true, message: '请输入密码!', validator: 'click' }] },
      }
    }
  },
  methods: {
    hotLine() {
      this.$refs.lineForm.open()
    },
    // 获取验证码
    obtainYzm() {
      if(this.username) {
        getAction('/mesnac/sys/sendYZM', {username: this.username}).then(res => {
          if(res.success) {
            this.$message.success('获取验证码成功,请在钉钉上查询！')
            this.codeTimer();
          }else {
            this.$message.warn(res.message)
          }
        })
      } else {
        this.$message.warn('请先输入用户名!')
      }
    },
    //验证码倒计时
    codeTimer() {
      let _this = this;
      if (this.timer >= 1) {
        this.$refs.eleCode.setAttribute("disabled", "true");
        this.codeText = this.timer + "s 后获取"
        // this.$refs.eleCode.setAttribute("value", this.timer + "s 后获取");
        this.$refs.eleCode.style.backgroundColor = "#F8DADA";
        this.$refs.eleCode.style.borderColor = "#F8DADA";
        this.timer--;
        setTimeout(function () {
          _this.codeTimer();
        }, 1000);
      } else {
        this.codeText = '获取验证码'
        // this.$refs.eleCode.setAttribute("value", this.codeText);
        this.$refs.eleCode.removeAttribute("disabled");
        this.$refs.eleCode.style.backgroundColor = "#bc0039";
        this.$refs.eleCode.style.borderColor = "#bc0039";
        this.timer = 60;
        this.clearYzm()
      }
    },
    // 对比验证码
    contrastOK() {
      // cDs9NAbD
      if(this.username && this.yzm) {
        this.confirmLoading = true
        getAction('/mesnac/sys/selectYZM', {YZM: this.yzm}).then(res => {
          if(res.success) {
            this.$message.success('验证成功，请填写新密码！')
            this.ifChange = true
          }else {
            this.$message.warn(res.message)
            this.yzm = ''
          }
        }).finally(()=> {
          this.confirmLoading = false
        })
      } else {
        this.$message.warn('请输入用户名和验证码!')
      }
    },
    // 修改密码
    handleSubmit() {
      let that = this
      that.loginBtn = true;
      that.form.validateFields([ 'username', 'password', ],{ force: true }, (err, values) => {
        if (!err) {
          let formData = Object.assign( values);
          getAction('/mesnac/sys/updateALL', formData).then(res => {
            if(res.success) {
              this.$message.success('修改密码成功，请重新登录！')
              this.clearYzm()
            } else {
              this.$message.warn(res.message)
            }
          }).finally(()=> {
            that.loginBtn = false;
            this.$router.push('/login')
          })
        }
      })
    },
    // 删除验证码
    clearYzm() {
      getAction('/mesnac/sys/deleteYZM', {YZM: this.yzm}).then(res => {
        console.log(res)
      }).finally(() => {
        this.yzm = ''
      })
    },
    handleUsernameOrEmail(rule, value, callback) {
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        this.loginType = 0
      } else {
        this.loginType = 1
      }
      callback()
    },
  }
}
</script>

<style lang="less" scoped>
.alteration{
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Source Han Sans CN";
}
.alteration::before{
    content: "";
    position: absolute;
    // background: url("~@/assets/img/login-bg.png") no-repeat bottom center;
    background: #f6f6f6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
}
.head-title {
  padding-top: 10px;
  margin: 0px 50px;
  display: flex;
  justify-content: space-between;
  .line{
    cursor: pointer;
  }
}
.login_form_wp {
  width:28%;
  // margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  // margin-top: 100px;
  border-radius: 20px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(188, 0, 57, 100);
  .login-logo{
    margin: 38px auto;
    width: 162px;
    height: 26px;
    background: url("~@/assets/img/login-logo.png") no-repeat top center / contain;
  }
  .login-title{
      color: rgba(188, 0, 57, 100);
      font-size: 33px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
      // font-family: 方正综艺体-标准;
  }
  .backLogin{
    margin: 20px 0px;
    text-align: right ;
    span{
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #bc0039;
      }
    }
  }
  .user-layout-alteration{
    margin: 30px 95px;
    .obtain{
      width: 100%;
      cursor: pointer;
      outline:none;
      right: 5px;
      bottom: 10px;
      color: #fff;
      background-color: #bc0039;
      border-radius: 5px;
      padding: 10px 0px;
      border-color: #bc0039;
      &:focus{
        // color: #bc0039;
        border-color: #bc0039;
      }

    }
    .contrast_btn_warp{
      margin-top: 30px;
      transition: 0.3s;
      .contrast{
        width: 360px;
        height: 55px;
        background-color: rgba(188, 0, 57, 100);
        color: rgba(255, 255, 255, 100);
        border: 1px solid rgba(188, 0, 57, 37);
        border-radius: 10px;
        font-size: 22px;
        text-align: center;
      }
      &:hover {
      transform: translateY(-.17857rem);
      filter: drop-shadow(4px 4px 5px #6e7ff3);
    }
    }
    
    /deep/ .ant-input{
        border-color:rgba(188, 0, 57, 37);
        // color: rgba(188, 0, 57, 37);
        ::-webkit-input-placeholder {
            color: rgba(188, 0, 57, 37);
        }
    }
  }
}
</style>


